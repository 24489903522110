#archive table {
  text-align: left;
}

#archive .big-heading-1 {
  margin: 60px 0;
}

#archive .table-headings {
  color: var(--slate);
}

#archive .project-tags {
  font-family: var(--secondary-font);
  font-size: 15px;
  display: flex;
  flex-direction: row;
}

#archive .project-tag {
  font-size: 12px;
  background: var(--background-color-light);
  padding: 5px;
  margin-right: 10px;
  border-radius: 5px;
}

#archive table {
  border-collapse: separate;
  border-spacing: 0 40px;
}

#archive .project-year {
  font-family: var(--secondary-font);
  color: var(--orange);
}
