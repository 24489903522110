#footer {
  padding: 25px 100px;
}

.footer-text {
  font-family: var(--secondary-font);
  font-size: 15px;
  text-align: center;
}

.footer-text span {
  color: var(--orange);
}

.footer-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.footer-icon {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}
